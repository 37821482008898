import {
    SET_COLUMN_SETTINGS_TYPE,
    SET_DEFAULT_FOR_ROLE_TYPE,
    SetColumnSettingsAction,
    SetDefaultsForRoleAction,
} from '../../actions/transportOrder/TableSettings.action';
import { Role } from '../../types';
import { RoleDefaultSettings, TableSettingsState, ValidColumn } from './types';

const DEFAULT_ROLE = Role.WAREHOUSE_EMPLOYEE;
const roleIndependentDefaults = {
    [ValidColumn.MARKERS]: {
        width: 40,
    },
    [ValidColumn.DIRECTION]: {
        width: 80,
    },
    [ValidColumn.ID]: {
        width: 80,
    },
    [ValidColumn.STATUS]: {
        width: 90,
    },
    [ValidColumn.ORIGIN]: {
        width: 80,
    },
    [ValidColumn.DESTINATIONS]: {
        width: undefined,
    },
    [ValidColumn.PICKUP_DATE]: {
        width: 100,
    },
    [ValidColumn.LICENSE_PLATE]: {
        width: undefined,
    },
    [ValidColumn.CARRIER_NAME]: {
        width: undefined,
    },
    [ValidColumn.EXTERNAL_REFERENCE_ID]: {
        width: 100,
    },
    [ValidColumn.CARGO_TYPE]: {
        width: undefined,
    },
    [ValidColumn.MASS_IN_KG]: {
        width: 80,
    },
    [ValidColumn.VOLUME_IN_M3]: {
        width: 80,
    },
    [ValidColumn.NUMBER_OF_PALLETS]: {
        width: 80,
    },
    [ValidColumn.PRIORITIZED]: {
        width: 80,
    },
    [ValidColumn.ORDER_TYPE]: {
        width: undefined,
    },
    [ValidColumn.VEHICLE_TYPE]: {
        width: undefined,
    },
    [ValidColumn.DANGEROUS_GOODS]: {
        width: 80,
    },
    [ValidColumn.STANDING_TIME]: {
        width: undefined,
    },
    [ValidColumn.STANDING_TIME_EXCEEDANCE]: {
        width: undefined,
    },
};

export const roleSpecificDefaults = () => {
    return {
        AUDITOR: {
            invalidColumns: [
                ValidColumn.DIRECTION,
                ValidColumn.STANDING_TIME,
            ],
            orderOfShownColumns: [
                ValidColumn.EXTERNAL_REFERENCE_ID,
                ValidColumn.PRIORITIZED,
                ValidColumn.STATUS,
                ValidColumn.ORIGIN,
                ValidColumn.DESTINATIONS,
                ValidColumn.PICKUP_DATE,
                ValidColumn.LICENSE_PLATE,
                ValidColumn.CARRIER_NAME,
                ValidColumn.CARGO_TYPE,
                ValidColumn.MASS_IN_KG,
                ValidColumn.VOLUME_IN_M3,
                ValidColumn.NUMBER_OF_PALLETS,
                ValidColumn.DANGEROUS_GOODS,
                ValidColumn.ORDER_TYPE,
                ValidColumn.VEHICLE_TYPE,
                ValidColumn.STANDING_TIME_EXCEEDANCE,
            ],
        },
        CARRIER: {
            invalidColumns: [
                ValidColumn.DIRECTION,
                ValidColumn.STANDING_TIME,
                ValidColumn.CARRIER_NAME,
            ],
            orderOfShownColumns: [
                ValidColumn.MARKERS,
                ValidColumn.ID,
                ValidColumn.PRIORITIZED,
                ValidColumn.STATUS,
                ValidColumn.ORIGIN,
                ValidColumn.DESTINATIONS,
                ValidColumn.PICKUP_DATE,
                ValidColumn.LICENSE_PLATE,
                ValidColumn.EXTERNAL_REFERENCE_ID,
                ValidColumn.CARGO_TYPE,
                ValidColumn.DANGEROUS_GOODS,
                ValidColumn.ORDER_TYPE,
                ValidColumn.VEHICLE_TYPE,
                ValidColumn.STANDING_TIME_EXCEEDANCE,
            ],
        },
        WAREHOUSE_EMPLOYEE: {
            invalidColumns: [
                ValidColumn.STANDING_TIME_EXCEEDANCE,
            ],
            orderOfShownColumns: [
                ValidColumn.MARKERS,
                ValidColumn.DIRECTION,
                ValidColumn.EXTERNAL_REFERENCE_ID,
                ValidColumn.PRIORITIZED,
                ValidColumn.STATUS,
                ValidColumn.ORIGIN,
                ValidColumn.DESTINATIONS,
                ValidColumn.PICKUP_DATE,
                ValidColumn.LICENSE_PLATE,
                ValidColumn.CARRIER_NAME,
                ValidColumn.CARGO_TYPE,
                ValidColumn.MASS_IN_KG,
                ValidColumn.VOLUME_IN_M3,
                ValidColumn.NUMBER_OF_PALLETS,
                ValidColumn.DANGEROUS_GOODS,
                ValidColumn.ORDER_TYPE,
                ValidColumn.VEHICLE_TYPE,
                ValidColumn.STANDING_TIME,
            ],
        },
    };
};

const createDefaultSettingsForRole = (role: Role): RoleDefaultSettings => {
    return (Object.keys(roleIndependentDefaults) as ValidColumn[])
        .filter((key) => !roleSpecificDefaults()[role].invalidColumns.includes(key))
        .reduce<any>((acc, key) => {
        const columnKey = key;
        return {
            ...acc,
            [key]: {
                ...roleIndependentDefaults[columnKey],
                hidden: !roleSpecificDefaults()[role].orderOfShownColumns.includes(columnKey),
            },
        };
    }, {});
};

const resetToDefault = (role: Role, defaultSettings: RoleDefaultSettings) => {
    const columnNamesOmittedInDefaultColumnsForRole = Object.entries(defaultSettings)
        .filter(([_, setting]) => setting.hidden)
        .map(([columnName]) => columnName as ValidColumn);

    return roleSpecificDefaults()[role].orderOfShownColumns
        .concat(columnNamesOmittedInDefaultColumnsForRole)
        .map((columnName: string) => ({
            id: columnName as ValidColumn,
            ...defaultSettings[columnName as ValidColumn],
        }));
};

const defaultSettingsForDefaultRole = createDefaultSettingsForRole(DEFAULT_ROLE);
const initialState: TableSettingsState = {
    defaultSettings: resetToDefault(DEFAULT_ROLE, defaultSettingsForDefaultRole),
    currentSettings: resetToDefault(DEFAULT_ROLE, defaultSettingsForDefaultRole),
    lastRole: DEFAULT_ROLE,
};

export type HandledActions = SetDefaultsForRoleAction | SetColumnSettingsAction;

export const tableSettingsReducer = (
    state: TableSettingsState = initialState,
    action: HandledActions,
): TableSettingsState => {
    switch (action.type) {
        case SET_COLUMN_SETTINGS_TYPE:
            return {
                ...state,
                currentSettings: action.payload,
            };
        case SET_DEFAULT_FOR_ROLE_TYPE:
            const role = action.payload;
            if (role === state.lastRole) {
                return { ...state };
            } else {
                const defaultSettings = resetToDefault(role, createDefaultSettingsForRole(role));
                return {
                    ...state,
                    defaultSettings,
                    currentSettings: defaultSettings,
                    lastRole: role,
                };
            }
        default:
            return { ...state };
    }
};
