import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { thunk as thunkMiddleware } from 'redux-thunk';
import { configureStore as configureStoreRtk, Tuple } from '@reduxjs/toolkit';

import { rootReducer } from '../../reducers';

const history = createBrowserHistory({});

function configureStore(preloadedState) {
    return configureStoreRtk({
        reducer: rootReducer(history),
        middleware: () => new Tuple(thunkMiddleware, routerMiddleware(history)),
        preloadedState,
        devTools: true,
    });
}

const store = configureStore();

export { history, store, configureStore };
